import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {Amplify, Auth} from 'aws-amplify';

import { environment } from '../../environments/environment';

export interface IUser {
  email: string;
  password: string;
  username: string;
  given_name: string;
  family_name: string;
  role: string;
  vendor_id: string;
  custom: boolean;
  access:string;
}

@Injectable({
  providedIn: 'root',
})
export class CognitoService {

  private authenticationSubject: BehaviorSubject<any>;

  constructor() {
    Amplify.configure({
      Auth: environment.cognito,
    });

    this.authenticationSubject = new BehaviorSubject<boolean>(false);
  }

  public async signUp(user: IUser): Promise<any> {
    return await Auth.signUp({
      username: user.email,
      password: user.password,
      attributes: {
        email: user.email,
        family_name: user.family_name,
        given_name: user.given_name,
        //email_verified: 'true',
        'custom:role': user.role,
        'custom:access': user.access,
        'custom:vendor_id': user.vendor_id,
        'custom:is_deleted': "0",
        'custom:is_verified': "1"
      },
      autoSignIn: { 
        enabled: true,
      }
    });
  }

  /* public confirmSignUp(user: IUser): Promise<any> {
    return Auth.confirmSignUp(user.email, user.code);
  } */

  public signIn(user: IUser): Promise<any> {
    return Auth.signIn(user.email, user.password)
    .then((auth) => {
      this.authenticationSubject.next(true);
      console.log("Cognito signin completed");
      return auth;
    });
  }

  public signOut(): Promise<any> {
    return Auth.signOut()
    .then(() => {
      localStorage.removeItem("user_info");
      localStorage.removeItem("user_type");
      this.authenticationSubject.next(false);
      console.log("Cognito signout completed");
    });
  }

  public isAuthenticated(){
    if (this.authenticationSubject.value) {
      return true;
    } else {
      return this.getUser()
      .then((user: any) => {
        if (user) {
          return true;
        } else {
          return false;
        }
      }).catch(() => {
        return false;
      });
    }
  }

  public async getUser(): Promise<any> {
    return await Auth.currentUserInfo();
  }

  public async updateUser(): Promise<any> {
    try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        console.log("Current user:", cognitoUser);

        let update = {'custom:is_verified': '1' }
        console.log("Current user:", cognitoUser);
        
        const updatedUser = await Auth.updateUserAttributes(cognitoUser, update);
        console.log("Updated user:", updatedUser);

        return updatedUser;
    } catch (error) {
        console.error("Error updating user:", error);
        throw error; // Rethrow the error to handle it at the caller level
    }
  }

  public updatePassword(oldPassword, newPassword): Promise<any> {
    return Auth.currentUserPoolUser()
    .then((cognitoUser: any) => {
      return Auth.changePassword(cognitoUser, oldPassword, newPassword);
    });
  }

  public async deleteUser() {
    try {
      const result = await Auth.deleteUser();
      console.log(result);
    } catch (error) {
      console.log('Error deleting user', error);
    }
  }

}