import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { VendorPortalService } from '../../vendor-portal.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-utilization-html',
  templateUrl: './utilization-html.component.html',
  styleUrls: ['./utilization-html.component.scss'],
  providers: [VendorPortalService]
})

export class UtilizationHTMLDownloadComponent implements OnInit {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  params: any = {};
  data: any = {};
  loadingIndicator: boolean = true;
  constructor(private vendorPortalService: VendorPortalService, public snackBar: MatSnackBar, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.params = params;
    });
    this.getUtlizationReportList();
  }

  ngOnInit() {
  }

  printScreen() {
    window.print();
  }

  public getUtlizationReportList(): void {
    this.loadingIndicator = true;
    this.vendorPortalService.getUtlizationReportList(this.params).subscribe(async (res) => {
        if(res?.statusCode == 200) {
            const temp = res?.body?.data?.results;
            /* temp.forEach((po: any) => {
              if(!(''+po['memberID'] in this.data)) {
                this.data[''+po['memberID']] = [];
              }
              this.data[''+po['memberID']].push(po);
            }); */
            this.data = temp;
            console.log(this.data);
            this.loadingIndicator = false;
        } else {
            this.openSnackBar('Failed to fetch the utilization reports list.', "Failed");
            this.data = {};
        }
    });    
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 12000
    });
  }

}
