import { Injectable } from "@angular/core"; 
import { 
	ActivatedRouteSnapshot, 
	CanActivate, 
	Router, 
	RouterStateSnapshot, 
	UrlTree 
} from "@angular/router"; 
import { CognitoService } from "./services/cognito.service";
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable() 
export class AuthGuard implements CanActivate { 
	constructor( 
		public snackBar: MatSnackBar,
		private authService: CognitoService, 
		private router: Router) { } 
	canActivate( 
		route: ActivatedRouteSnapshot, 
		state: RouterStateSnapshot): boolean | Promise<boolean> { 
		var isAuthenticated = this.authService.isAuthenticated();
		console.log(state);
		console.log(isAuthenticated);
		if (!isAuthenticated) { 
			this.router.navigate(['/login']); 
			return false;
		} 
		var userinfo: any = localStorage.getItem("user_info");
		console.log(userinfo);
		if(!userinfo) {
			this.router.navigate(['/login']);
			this.openSnackBar("User session expired.", "Failed");
			return false;
		} else {
			var loggedInUser = "admin"; 
			if(state?.url?.includes("/member/")) {
				loggedInUser = 'member';
			} else if (state?.url?.includes("/vendor_admin/")) {
				loggedInUser = 'vendor';
			} else {
				loggedInUser = 'admin';
			}
			userinfo = JSON.parse(userinfo);
			console.log(state?.url, loggedInUser, userinfo["custom:role"]);
			if(userinfo && userinfo["custom:role"] == 'member' && loggedInUser == 'member') {
				console.log("Inside member auth guard");
				return true;
			} else if(userinfo && userinfo["custom:role"] == 'admin' && loggedInUser == 'admin'){
				console.log("Inside admin auth guard");
				return true;
			} else if(userinfo && userinfo["custom:role"] == 'vendor' && loggedInUser == 'vendor'){
				console.log("Inside admin auth guard");
				return true;
			} else {
				console.log("Inside auth guard about to logout");
				localStorage.removeItem("user_info");
      			localStorage.removeItem("user_type");
				this.router.navigate(['/login']);
				return false;
			}
		} 
	} 

	openSnackBar(message: string, action: string) {
		this.snackBar.open(message, action, {
		  duration: 12000
		});
	}
} 