import { Menu } from './menu.model';

export const verticalMenuItems = [
    new Menu(1, 'Dashboard', '/', null, 'dashboard', null, false, 0),
    new Menu(2, 'Users', '/users', null, 'supervisor_account', null, false, 0),
    new Menu(3, 'UI Features', null, null, 'computer', null, true, 0),
    new Menu(4, 'Buttons', '/ui/buttons', null, 'keyboard', null, false, 3),
    new Menu(5, 'Cards', '/ui/cards', null, 'card_membership', null, false, 3),
    new Menu(15, 'Dynamic Menu', '/dynamic-menu', null, 'format_list_bulleted', null, false, 0),
    new Menu(16, 'Mailbox', '/mailbox', null, 'email', null, false, 0),
    new Menu(17, 'Chat', '/chat', null, 'chat', null, false, 0),
    new Menu(20, 'Form Controls', null, null, 'dvr', null, true, 0),
    new Menu(21, 'Autocomplete', '/form-controls/autocomplete', null, 'short_text', null, false, 20),
    new Menu(22, 'Checkbox', '/form-controls/checkbox', null, 'check_box', null, false, 20),
    new Menu(23, 'Datepicker', '/form-controls/datepicker', null, 'today', null, false, 20),
    new Menu(24, 'Form field', '/form-controls/form-field', null, 'view_stream', null, false, 20),
    new Menu(25, 'Input', '/form-controls/input', null, 'input', null, false, 20),
    new Menu(26, 'Radio button', '/form-controls/radio-button', null, 'radio_button_checked', null, false, 20),
    new Menu(27, 'Select', '/form-controls/select', null, 'playlist_add_check', null, false, 20),
    new Menu(28, 'Slider', '/form-controls/slider', null, 'tune', null, false, 20),
    new Menu(29, 'Slide toggle', '/form-controls/slide-toggle', null, 'star_half', null, false, 20),
    new Menu(30, 'Tables', null, null, 'view_module', null, true, 0),
    new Menu(31, 'Basic', '/tables/basic', null, 'view_column', null, false, 30),
    new Menu(32, 'Paging', '/tables/paging', null, 'last_page', null, false, 30),
    new Menu(33, 'Sorting', '/tables/sorting', null, 'sort', null, false, 30),
    new Menu(34, 'Filtering', '/tables/filtering', null, 'format_line_spacing', null, false, 30),
    new Menu(35, 'Selecting', '/tables/selecting', null, 'playlist_add_check', null, false, 30),
    new Menu(36, 'NGX DataTable', '/tables/ngx-table', null, 'view_array', null, false, 30),
    new Menu(40, 'Logout', null, null, 'library_books', null, false, 0),

    new Menu(50, 'Projects', '/profile/projects', null, 'note', null, false, 49),
    new Menu(51, 'User Info', '/profile/user-info', null, 'perm_contact_calendar', null, false, 49),
    new Menu(55, 'Schedule', '/schedule', null, 'event', null, false, 0),
    new Menu(66, 'Maps', null, null, 'map', null, true, 0),
    new Menu(67, 'Google Maps', '/maps/googlemaps', null, 'location_on', null, false, 66),
    new Menu(68, 'Leaflet Maps', '/maps/leafletmaps', null, 'my_location', null, false, 66),
    new Menu(70, 'Charts', null, null, 'multiline_chart', null, true, 0),
    new Menu(71, 'Bar Charts', '/charts/bar', null, 'insert_chart', null, false, 70),
    new Menu(72, 'Pie Charts', '/charts/pie', null, 'pie_chart', null, false, 70),
    new Menu(73, 'Line Charts', '/charts/line', null, 'show_chart', null, false, 70),
    new Menu(74, 'Bubble Charts', '/charts/bubble', null, 'bubble_chart', null, false, 70),
    new Menu(81, 'Drag & Drop', '/drag-drop', null, 'mouse', null, false, 0),
    new Menu(85, 'Material Icons', '/icons', null, 'insert_emoticon', null, false, 0),
    new Menu(140, 'Level 1', null, null, 'more_horiz', null, true, 0),
    new Menu(141, 'Level 2', null, null, 'folder_open', null, true, 140),
    new Menu(142, 'Level 3', null, null, 'folder_open', null, true, 141),
    new Menu(143, 'Level 4', null, null, 'folder_open', null, true, 142),
    new Menu(144, 'Level 5', null, 'http://themeseason.com', 'link', null, false, 143),
    new Menu(200, 'External Link', null, 'http://themeseason.com', 'open_in_new', '_blank', false, 0)
]

export const horizontalMenuItems = [
    new Menu(1, 'Overview', '/admin/', null, 'dashboard', null, false, 0),
    new Menu(2, 'Panel', '/admin/panel', null, 'supervisor_account', null, false, 0),
    new Menu(3, 'Members', null, null, 'computer', null, true, 0),
    new Menu(4, 'Membership by State', '/admin/ui/buttons', null, 'keyboard', null, false, 3),
    new Menu(6, 'Member List', '/admin/members', null, 'card_membership', null, false, 3),
    new Menu(70, 'Vendors', null, null, 'multiline_chart', null, true, 0),
    new Menu(73, 'Settings', null, null, 'settings', null, true, 0),
    new Menu(74, 'Database Users', '/admin/users', null, 'supervisor_account', null, false, 73),
    new Menu(75, 'Vendor Home', '/admin/vendors/vendor-home', null, 'supervisor_account', null, false, 73),
    new Menu(72, 'Vendors List', '/admin/vendors', null, 'pie_chart', null, false, 70),
    new Menu(76, 'Commodities List', '/admin/vendors/commodities', null, 'note', null, false, 70),
    new Menu(40, 'Logout', '/logout', null, 'logout', null, false, 0),
    new Menu(50, 'Projects', '/admin/profile/projects', null, 'note', null, false, 49),
    new Menu(51, 'User Info', '/admin/profile/user-info', null, 'perm_contact_calendar', null, false, 49)
]


export const horizontalMemberMenuItems = [
    new Menu(1, 'Home', '/member/home', null, 'dashboard', null, false, 0),
    new Menu(2, 'Reports', '/', null, 'multiline_chart', null, true, 0),
    new Menu(3, 'My Entity', '/member/entities', null, 'settings', null, false, 0),
    new Menu(4, 'Communication', '/', null, 'email', null, true, 0),
    new Menu(5, 'All Contracts', '/member/contracts', null, 'pie_chart', null, false, 0),
    new Menu(6, 'All Vendors', '/member/vendors', null, 'group', null, false, 0),
    new Menu(7, 'BID Schedule', '/member/bid-schedule', null, 'paid', null, false, 0),
    new Menu(151, 'Open POs', '/member/pos', null, 'multiline_chart', null, false, 2),
    new Menu(152, 'Current Year POs', '/member/current-pos', null, 'multiline_chart', null, false, 2),
    new Menu(153, 'Prior Year POs', '/member/prior-pos', null, 'multiline_chart', null, false, 2),
    new Menu(40, 'Logout', '/logout', null, 'logout', null, false, 0),
    new Menu(154, 'Email to TIPS', 'email', null, 'email', null, false, 4),
    new Menu(158, 'All POs', '/member/submit-po', null, 'email', null, false, 4),
    new Menu(159, 'Documents', '/member/documents', null, 'email', null, false, 4),
    new Menu(157, 'Request for Quote', 'request', null, 'email', null, false, 4),
    new Menu(155, 'TIPS Contacts', null, 'https://www.tips-usa.com/assets/documents/docs/Vendor_Files/tipscontacts.pdf', 'email', '_blank', false, 4),
    new Menu(156, 'TIPS Representatives', null, 'https://www.tips-usa.com/assets/documents/docs/Vendor_Files/map.pdf', 'email', '_blank', false, 4)
]

    
export const horizontalVendorMenuItems = [
    new Menu(1, 'Home', '/vendor_admin/home', null, 'dashboard', null, false, 0),
    new Menu(2, 'Reports', '/', null, 'multiline_chart', null, true, 0),
    new Menu(3, 'My Company', '/vendor_admin/entities', null, 'settings', null, true, 0),
    new Menu(4, 'POs and Payments', '/vendor_admin/pos_payments', null, 'email', null, false, 0),
    new Menu(5, 'Communication', '/vendor_admin/contracts', null, 'pie_chart', null, true, 0),
    new Menu(6, 'Vendor Training', '/vendor_admin/vendor_training', null, 'group', null, false, 0),
    new Menu(151, 'Member Quick Search', '/vendor_admin/member_search', null, 'multiline_chart', null, false, 2),
    new Menu (153, 'Download Members', '/vendor_admin/member_download', null, 'multiline_chart', null, false, 2),
    new Menu (153, 'Member Reports', '/vendor_admin/member_reports', null, 'multiline_chart', null, false, 2),
    new Menu (7, 'BID Schedule', '/vendor_admin/bid_schedule', null, 'paid', null, false, 2),
    new Menu (40, 'Logout', '/logout', null, 'logout', null, false, 0),
    new Menu(154, 'Company Profile', '/vendor_admin/company_profile', null, 'email', null, false, 3),
    new Menu(160, 'Change my Password', '/vendor_admin/change_password', null, 'email', null, false, 3),
    new Menu(161, 'Authorized Users', '/vendor_admin/authorised_users', null, 'email', null, false, 3),
    new Menu(155, 'Email to Tips',  '/vendor_admin/email_to_tips', null, 'email', null, false, 5),
    new Menu(156, 'Documents', null, 'https://www.tips-usa.com/assets/documents/docs/Vendor_Files/map.pdf', 'email', '_blank', false, 5),
    // new Menu(155, 'Submit Files', null, 'https://www.tips-usa.com/assets/documents/docs/Vendor_Files/tipscontacts.pdf', 'email', '_blank', false, 5),
    new Menu(155, 'TIPS Contacts', null, 'https://www.tips-usa.com/assets/documents/docs/Vendor_Files/tipscontacts.pdf', 'email', '_blank', false, 5),
    new Menu(156, 'TIPS Representatives', null, 'https://www.tips-usa.com/assets/documents/docs/Vendor_Files/map.pdf', 'email', '_blank', false, 5)
]

