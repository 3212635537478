import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class VendorPortalService {

  posListUrl = "https:///api-staging.tips-usa.com/mv/po-list?filter=completed&memberID=220902";
  stage_url = "https:///api-staging.tips-usa.com/mv/";
  new_stage_url = "https:///api-staging.tips-usa.com/admin/"
  vendor_stage_url = "https:///api-staging.tips-usa.com/v1/";
  //v1/vendor/po-history

  sessionData: any = localStorage.getItem("user_info");
  vendor_id: any;
  constructor(public http: HttpClient) {
    this.vendor_id = JSON.parse(this.sessionData)['custom:vendor_id'];
  }

  getPOHistory(params): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.get<any>(this.stage_url + 'vendor/po-history?' + params + '&vendor_id=' + this.vendor_id, {
      headers: headers
    });
  }

  getMembers(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.get<any>(this.stage_url + 'vendor/members', {
      headers: headers
    });
  }

  getContractsss(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.get<any>(this.stage_url + 'vendor/contracts', {
      headers: headers
    });
  }

  getOpenPOPayments(filter: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(this.stage_url + 'vendor/po-payments', filter, {
      headers: headers
    });
  }

  addToReports(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(this.stage_url + 'vendor/add-to-payments', data, {
      headers: headers
    });
  }

  getViewTips(vendor_id: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.get<any>(this.stage_url + 'vendor/tips-report?vendor_id=' + vendor_id, {
      headers: headers
    });
  }

  getPriorReports(vendorId: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.get<any>(this.stage_url + 'vendor/prior-reports?vendor_id=' + vendorId, {
      headers: headers
    });
  }

  getStates(): Observable<any> {
    return this.http.get<any>(this.vendor_stage_url + 'states-list');
  }

  getNewContracts(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.get<any>(this.stage_url + 'contracts-dropdown-list', {
      headers: headers
    });
  }

  getMembersDropdown(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.get<any>(this.vendor_stage_url + 'members/dropdown-new', {
      headers: headers
    });
  }

  getVendorContractsList(filter: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(this.stage_url + 'vendor/company-contracts-list', filter, {
      headers: headers
    });
  }

  getUtlizationReportList(filter: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(this.stage_url + 'vendor/utilization-report', filter, {
      headers: headers
    });
  }

  getMemberReceivableReportList(filter: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(this.stage_url + 'vendor/member-receivable', filter, {
      headers: headers
    });
  }

  getExportMembersList(filter: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(this.stage_url + 'member/export-records', filter, {
      headers: headers
    });
  }

  revertPO(processID): Observable<any> {
    let params = { line: processID }
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(this.stage_url + 'vendor/revert-po', params, {
      headers: headers
    });
  }

  createVendorContact(params): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(this.stage_url + 'vendor/create-vendor-contact', params, {
      headers: headers
    });
  }

  getVendorContacts(vendorID): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.get<any>(this.new_stage_url + 'vendor/vendor-users?vendor_id=' + this.vendor_id, {
      headers: headers
    });
  }

  deleteVendorContact(params): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(this.stage_url + 'vendor/delete-vendor-contact', params, {
      headers: headers
    });
  }

  getVendorDetails(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.get<any>(this.stage_url + 'vendor/details?vendorID=' + this.vendor_id, {
      headers: headers
    });
  }

  submitTips(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.get<any>(this.stage_url + 'vendor/details?vendorID=' + this.vendor_id, {
      headers: headers
    });
  }

}
