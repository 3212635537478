import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router'; 
import { UtilizationHTMLDownloadComponent } from '../app/pages/vendor-portal/member-reports/utilization-html/utilization-html.component';

import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ErrorComponent } from './pages/errors/error/error.component';
import { AuthGuard }  from './auth.guards'; 

export const routes: Routes = [
    
    { 
        path: 'admin', 
        component: PagesComponent, children: [
            { path: '',  canActivate: [AuthGuard],loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' } },
            { path: 'users', canActivate: [AuthGuard],loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule), data: { breadcrumb: 'Users' } },
            { path: 'panel', canActivate: [AuthGuard],loadChildren: () => import('./pages/panel/panel.module').then(m => m.PanelModule), data: { breadcrumb: 'Panel' } },
            { path: 'members', canActivate: [AuthGuard],loadChildren: () => import('./pages/members/member.module').then(m => m.MembersModule), data: { breadcrumb: 'Members' } },
            { path: 'vendors', canActivate: [AuthGuard],loadChildren: () => import('./pages/vendors/vendor.module').then(m => m.VendorsModule), data: { breadcrumb: 'Vendors' } },
            { path: 'ui', canActivate: [AuthGuard],loadChildren: () => import('./pages/ui/ui.module').then(m => m.UiModule), data: { breadcrumb: 'UI' } },
            { path: 'form-controls', canActivate: [AuthGuard],loadChildren: () => import('./pages/form-controls/form-controls.module').then(m => m.FormControlsModule), data: { breadcrumb: 'Form Controls' } },
            { path: 'tables', canActivate: [AuthGuard],loadChildren: () => import('./pages/tables/tables.module').then(m => m.TablesModule), data: { breadcrumb: 'Tables' } },
            { path: 'icons', canActivate: [AuthGuard], loadChildren: () => import('./pages/icons/icons.module').then(m => m.IconsModule), data: { breadcrumb: 'Material Icons' } },
            { path: 'drag-drop', canActivate: [AuthGuard],loadChildren: () => import('./pages/drag-drop/drag-drop.module').then(m => m.DragDropModule), data: { breadcrumb: 'Drag & Drop' } },
            { path: 'schedule', canActivate: [AuthGuard],loadChildren: () => import('./pages/schedule/schedule.module').then(m => m.ScheduleModule), data: { breadcrumb: 'Schedule' } },
            { path: 'mailbox', canActivate: [AuthGuard], loadChildren: () => import('./pages/mailbox/mailbox.module').then(m => m.MailboxModule), data: { breadcrumb: 'Mailbox' } },
            { path: 'chat', canActivate: [AuthGuard],loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatModule), data: { breadcrumb: 'Chat' } },
            { path: 'maps', canActivate: [AuthGuard],loadChildren: () => import('./pages/maps/maps.module').then(m => m.MapsModule), data: { breadcrumb: 'Maps' } },
            { path: 'charts', canActivate: [AuthGuard],loadChildren: () => import('./pages/charts/charts.module').then(m => m.ChartsModule), data: { breadcrumb: 'Charts' } },
            { path: 'dynamic-menu', canActivate: [AuthGuard],loadChildren: () => import('./pages/dynamic-menu/dynamic-menu.module').then(m => m.DynamicMenuModule), data: { breadcrumb: 'Dynamic Menu' }  },          
            { path: 'profile', canActivate: [AuthGuard],loadChildren: () => import ('./pages/profile/profile.module').then(m => m.ProfileModule), data: { breadcrumb: 'Profile' } }, 
            { path: 'blank', component: BlankComponent, data: { breadcrumb: 'Blank page' } },
            { path: 'search', component: SearchComponent, data: { breadcrumb: 'Search' } }
        ]
    },
    { 
        path: 'member', 
        component: PagesComponent, children: [
            { path: '', canActivate: [AuthGuard],loadChildren: () => import('./pages/member-portal/member-portal.module').then(m => m.MemberPortalModule) }
        ]

    },
    { 
        path: 'vendor_admin', 
        component: PagesComponent, children: [
            { path: '', canActivate: [AuthGuard],loadChildren: () => import('./pages/vendor-portal/vendor-portal.module').then(m => m.VendorPortalModule) }
        ]

    },
    { path: 'utlization-html', component: UtilizationHTMLDownloadComponent},
    { path: 'landing', loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule) },
    { path: '', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
    { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
    { path: 'change-password', loadChildren: () => import('./pages/change-password/change-password.module').then(m => m.ChangePasswordModule) },
    { path: 'logout', loadChildren: () => import('./pages/logout/logout.module').then(m => m.LogoutModule) },
    { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
    { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } },
    { path: '**', component: NotFoundComponent }
]; 

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
})
    ],
    exports: [
        RouterModule
    ],
    providers: [AuthGuard]
})
export class AppRoutingModule { }